<template>
  <form :id="`step${step}`" @submit.prevent="$emit('submitForm')">
    <template v-for="(field, index) in fieldsInStep">
      <template v-if="!field.component">
        <FormInput
          :key="index"
          :data="field"
          :product="product"
          v-on:formFunction="formStepFunction"
        />
      </template>
      <template v-else-if="field.component == 'VehicleInformation'">
        <VehicleInformation
          :key="index"
          :data="field"
          :product="product"
          v-on:formFunction="formStepFunction"
        />
      </template>
    </template>
  </form>
</template>

<script>
import FormInput from "@/components/FormInput.vue";
import VehicleInformation from "@/components/VehicleInformation.vue";

export default {
  name: "FormStep",
  components: {
    FormInput,
    VehicleInformation,
  },
  props: {
    fields: {
      type: Object,
      default: null,
    },
    product: {
      type: Object,
      default: null,
    },
    step: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    // List of fields inside current step
    fieldsInStep() {
      // Define array for returning
      let fields = new Array();

      // If field is in current step, insert into array
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.fields)) {
        if (value.step == this.step) {
          fields.push(value);
        }
      }
      return fields;
    },
  },
  methods: {
    formStepFunction(functionName, parameter) {
      this.$emit("formFunction", functionName, parameter);
    },
  },
};
</script>
