<template>
  <div>
    <div v-if="registrationData" class="vehicle-information">
      <i class="fa-light fa-fw vehicle-icon" :class="vehicleIcon"></i>
      <div class="vehicle-information-text">
        <span class="registration-number">Oplysninger for <b>"{{ registrationNumber }}"</b></span>
        <table class="vehicle-information-table">
          <tr>
            <td>Mærke</td>
            <td>{{ maerke }}</td>
          </tr>
          <tr>
            <td>Model</td>
            <td>{{ model }}</td>
          </tr>
          <tr>
            <td>Årgang</td>
            <td>{{ modelYear }}</td>
          </tr>
          <tr>
            <td>Variant</td>
            <td>{{ variant }}</td>
          </tr>
          <tr>
            <td>Type</td>
            <td>{{ type }}</td>
          </tr>
          <tr v-if="productCode == 'M21'">
            <td>Veteranregistreret</td>
            <td>{{ veteranApproved }}</td>
          </tr>
        </table>
      </div>
    </div>
    <input
      type="checkbox"
      :required="data.required"
      :checked="registrationData"
      @invalid.prevent="onInvalid"
      v-show="false"
    />
  </div>
</template>

<script>
export default {
  name: "VehicleInformation",
  props: {
    data: {
      type: Object,
      default: null,
    },
    product: {
      type: Object,
      default: null,
    },
  },
  computed: {
    registrationData() {
      try {
        return this.product.fields.registrationData.value;
      } catch {
        return null;
      }
    },
    registrationNumber() {
      return this.product.fields.registrationNumber.value;
    },
    // Product code
    productCode() {
      return this.product.productId;
    },
    // Mærke field
    maerke() {
      if (
        this.registrationData &&
        this.registrationData.extraCarInformation.maerke
      ) {
        return this.registrationData.extraCarInformation.maerke;
      } else {
        return "Ukendt";
      }
    },
    // Model field
    model() {
      if (
        this.registrationData &&
        this.registrationData.extraCarInformation.model
      ) {
        return this.registrationData.extraCarInformation.model;
      } else {
        return "Ukendt";
      }
    },
    // Model year field
    modelYear() {
      if (this.registrationData && this.registrationData.data.modelYear) {
        return this.registrationData.data.modelYear;
      } else {
        return "Ukendt";
      }
    },
    // Variant field
    variant() {
      if (
        this.registrationData &&
        this.registrationData.extraCarInformation.variant
      ) {
        return this.registrationData.extraCarInformation.variant;
      } else {
        return "Ukendt";
      }
    },
    // Type field
    type() {
      if (
        this.registrationData &&
        this.registrationData.extraCarInformation.artsNavn
      ) {
        return this.registrationData.extraCarInformation.artsNavn;
      } else {
        return "Ukendt";
      }
    },
    veteranApproved() {
      let tilladelse = "Nej";
      if (this.registrationData && this.registrationData.extraCarInformation && this.registrationData.extraCarInformation.tilladelse) {
        this.registrationData.extraCarInformation.tilladelse.forEach((element) => {
            if (element.tilladelseNummer == "14") tilladelse = "Ja";
          }
        );
      }
      return tilladelse;
    },
    // Get vehicle icon
    vehicleIcon() {
      if (this.registrationData && this.registrationData.extraCarInformation) {
        switch (this.registrationData.extraCarInformation.artsNummer) {
          case "1":
            return this.registrationData.data &&
              this.registrationData.data.fuelType == "EL"
              ? "fa-car-side-bolt"
              : "fa-car-side";
          case "2":
            return "fa-van-shuttle";
          case "3":
            return "fa-truck";
          case "4":
            return "fa-truck-moving";
          case "7":
            return "fa-motorcycle";
          case "8":
            return "fa-tractor";
          case "10":
            return "fa-caravan";
          case "9":
          case "11":
            return "fa-trailer";
          case "12":
            return "fa-truck-plow";
          case "16":
          case "17":
            return "fa-moped";
          case "6":
          case "91":
          case "96":
            return "fa-truck-container";
          default:
            return "fa-car-side";
        }
      } else {
        return "fa-car-side";
      }
    },
  },
  methods: {
    /*
     * Function to run on invalid
     */
    onInvalid() {
      if (this.data.onInvalid) {
        this.$emit("formFunction", this.data.onInvalid);
      }
    },
  },
};
</script>
